<template>
    <el-dialog
            title="注销信息填写"
            :visible.sync="dialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-form size="small" ref="inputForm"
                 :model="inputForm"
                 :rules="rules" label-width="150px">
            <el-row :gutter="10">
<!--                <el-col :span="12">-->
<!--                    <el-form-item label="是否注销鉴定" prop="logoffIdentify">-->
<!--                        <el-select v-model="inputForm.logoffIdentify" clearable-->
<!--                                   placeholder="请选择是否注销鉴定" style="width: 100%;">-->
<!--                            <el-option-->
<!--                                    v-for="item in logoffIdentifyList"-->
<!--                                    :key="item.value"-->
<!--                                    :label="item.label"-->
<!--                                    :value="item.value">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
                <el-col :span="12">
                    <el-form-item label="注销原因" prop="logoffReason">
                        <el-select v-model="inputForm.logoffReason" clearable
                                   placeholder="请选择注销原因" style="width: 100%;">
                            <el-option
                                    v-for="item in this.$dictUtils.getDictList('logoff_reason')"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="注销原因描述" prop="logoffReasonDesc">
                        <el-input
                                v-model="inputForm.logoffReasonDesc"
                                clearable
                                type="textarea"
                                :rows="3"
                                maxlength="200"
                                placeholder="请输入注销原因描述(限200字)">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="注销类型" prop="logoffTypes">
                        <el-input
                                v-model="inputForm.logoffTypes"
                                clearable
                                type="textarea"
                                :rows="3"
                                maxlength="200"
                                placeholder="请输入注销类型描述(限200字)">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="注销文物的影响" prop="logoffCultural">
                        <el-select v-model="inputForm.logoffCultural" clearable
                                   placeholder="请选择注销文物的影响" style="width: 100%;">
                            <el-option
                                    v-for="item in this.$dictUtils.getDictList('logoff_cultural')"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="注销文物的影响描述" prop="logoffCulturalDesc">
                        <el-input
                                v-model="inputForm.logoffCulturalDesc"
                                clearable
                                type="textarea"
                                :rows="3"
                                maxlength="200"
                                placeholder="请输入注销文物的影响描述(限200字)">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="text_center">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" v-noMoreClick type="primary" @click="aubAllData()">提交</el-button>
        </div>
    </el-dialog>
</template>

<script>

export default {
    name: "breachLogoff",
    data() {
        return {
            dialogVisible: false,
            inputForm: {
                logoffIdentify: '',
                logoffReason: '',
                logoffReasonDesc: '',
                logoffTypes: '',
                logoffCultural: '',
                logoffCulturalDesc: '',
            },
            rules: {
                logoffReason: [
                    {required: true, message: '请选择注销原因', trigger: 'change'}
                ],
                logoffCultural: [
                    {required: true, message: '请选择注销文物的影响', trigger: 'change'}
                ],
            },
            logoffIdentifyList: [
                {
                    value: 0,
                    label: '是',
                },
                {
                    value: 1,
                    label: '否',
                },
            ],
            ids: [],
            revdId: '',
            entryState:'',
        }
    },

    methods: {
        init(ids, revdId,entryState) {
            this.ids = ids
            this.revdId = revdId
            this.entryState = entryState
            this.dialogVisible = true
        },

        //提交
        aubAllData() {
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    this.$axios(this.api.collection.logoffBacthSubmit, {
                        ...this.inputForm,
                        ids: this.ids,
                        revdId: this.revdId,
                        entryState: this.entryState,
                        isWarehousing:'0',
                    }, "post").then((data) => {
                        if (data && data.status) {
                            this.$message.success('提交成功')
                            this.$emit('logSuccess')
                            this.handleClose()
                        } else {
                            this.$message.error(data.msg)
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        handleClose() {
            this.$refs.inputForm.resetFields();
            this.dialogVisible = false;
        }
    },
}
</script>

<style scoped>

</style>